.customModalOverlay {
  z-index: 9000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.customModalContainer {
  z-index: 9001;
  width: 600px;
  max-width: 98%;
  position: fixed;

  height: 400px;
  max-height: 98%;

  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  background-color: #fff;

  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding-box;
  background-clip: padding-box;
}
.customModalContainerSmall {
  height: 150px;
}
.customModalContainerMedium {
  height: 250px;
}
.customModalContainer .header {
  border-bottom: 1px solid #ccc;
  padding: 1rem;
  margin: 0;
}
.customModalContainer .body {
  padding: 1rem;
  height: 90%;
  background-color: #fff;
  overflow-y: auto;
}
.customModalContainer footer {
  border-top: 1px solid #ccc;
  background-color: #eee;
  padding: 0.5rem 1rem;
  height: 60px;
  display: flex;
  justify-content: space-between;
}
